import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-5b707f7c"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "skeleton"
};
export function render(_ctx, _cache) {
  const _component_van_skeleton = _resolveComponent("van-skeleton");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_skeleton, {
    class: "banner",
    avatar: "",
    "avatar-size": "100vw",
    "avatar-shape": "square"
  }), _createVNode(_component_van_skeleton, {
    class: "nav_img",
    avatar: "",
    "avatar-size": "96vw",
    "avatar-shape": "square"
  }), _createVNode(_component_van_skeleton, {
    class: "content",
    title: "",
    "row-width": "96vw",
    row: 9
  })]);
}