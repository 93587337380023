import { Toast } from '@utils/tool';
/**
 * @desc 页面公共hooks
 * */

export default function commMixins() {
  // 路由获取
  const route = useRoute(); // 获取params参数

  const getRouteParams = () => route.params; // 获取query参数


  const getRouteQuery = () => route.query; // 判断对象中是否包含某个参数


  const isObjectParameter = (obj, name) => {
    return Reflect.has(obj, name);
  }; // 检测query参数是否存在


  const urlQueryCheck = (key, msg) => {
    const query = getRouteQuery();

    if (query) {
      if (!isObjectParameter(query, key)) {
        Toast(msg);
        return false;
      }

      return true;
    } else {
      Toast('query参数为空');
      return true;
    }
  };

  return {
    getRouteParams,
    getRouteQuery,
    isObjectParameter,
    urlQueryCheck,
    Toast
  };
}